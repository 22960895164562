import styled from 'styled-components';

const AboutProjectSectionStyle = styled.div`
	--aboutContentMarginBottom: var(--sp30x);
	--sectionTitleMarginBottom: var(--sp7x);
	--aboutItemColMarginTop: calc(var(--sp13x) * -1);

	margin-bottom: var(--sectionDistance);

	.about-content-wrap {
		margin-bottom: var(--aboutContentMarginBottom);

		.section-title {
			overflow: hidden;
			margin-bottom: var(--sectionTitleMarginBottom);
		}

		.section-description {
			/* overflow: hidden; */

			.description {
				div {
					will-change: transform;
				}
			}
		}
	}

	.about-items-container {
		padding-top: calc(var(--aboutItemColMarginTop) * -1);

		.about-item-col {
			margin-top: var(--aboutItemColMarginTop);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--aboutContentMarginBottom: var(--sp25x);
		--sectionTitleMarginBottom: var(--sp5x);
		--aboutItemColMarginTop: calc(var(--sp7x) * -1);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--aboutContentMarginBottom: var(--sp20x);
		--sectionTitleMarginBottom: var(--sp5x);
		--aboutItemColMarginTop: calc(var(--sp5x) * -1);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--aboutContentMarginBottom: var(--sp20x);
		--sectionTitleMarginBottom: var(--sp5x);
		--aboutItemColMarginTop: calc(var(--sp4x) * -1);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--aboutContentMarginBottom: var(--sp15x);
		--sectionTitleMarginBottom: var(--sp4x);
		--aboutItemColMarginTop: calc(var(--sp6x) * -1);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--aboutContentMarginBottom: var(--sp12x);
		--sectionTitleMarginBottom: var(--sp4x);
		--aboutItemColMarginTop: calc(var(--sp5x) * -1);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--aboutContentMarginBottom: var(--sp12x);
		--sectionTitleMarginBottom: var(--sp4x);
		--aboutItemColMarginTop: var(--sp3x);

		.about-items-container {
			margin-top: calc(var(--aboutItemColMarginTop) * -1);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--aboutContentMarginBottom: var(--sp9x);
		--sectionTitleMarginBottom: var(--sp3x);
		--aboutItemColMarginTop: var(--sp5x);

		.about-items-container {
			margin-top: calc(var(--aboutItemColMarginTop) * -1);

			.about-item-col {
				.about-item {
					&.odd {
						.image-cont {
							margin-top: unset !important;
						}
					}
				}
			}
		}
	}
`;

export default AboutProjectSectionStyle;
