'use client';
import { memo as Memo, useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';

//* HOC's
import { withDataContext, withUIContext } from '@/context';

//* Style
import HomeStyle from './HomeStyle';

//* Component's
import Page from '@/components/common/Page';
import VideoSection from '@/components/sections/VideoSection';
import FacilitiesSection from './components/FacilitesSection';
import HeroSection from '@/modules/home/components/HeroSection';
import GallerySection from '@/components/sections/GallerySection';
import AboutProjectSection from '@/modules/home/components/AboutProjectSection';
import PartnersSection from './components/PartnersSection';
import CallToAction from '@/components/common/CallToAction';
import Resize from '@/components/common/ScrollActive/Resize';

const LocationSection = dynamic(() => import('./components/LocationSection'), { ssr: false });

const HomeTemplate = Memo(({ pageData, setPartners }) => {
	//! Ref
	const locationRef = useRef();

	//! State
	const [resize, setResize] = useState(false);

	useEffect(() => {
		setPartners(pageData.partner);
	}, [pageData.partner]);

	return (
		<Page className={'home'}>
			<HomeStyle>
				<Resize
					refCont={locationRef.current}
					setResize={setResize}>
					{resize ? (
						<>
							<HeroSection />
							<AboutProjectSection {...pageData.about_project} />

							<FacilitiesSection />
						</>
					) : null}
					<div
						ref={locationRef}
						className={`wrapper-location ${resize ? '' : 'opacity-0'}`}>
						<LocationSection />
					</div>
					{resize ? (
						<>
							<VideoSection
								gallery={pageData.video.item}
								title={pageData.video.title}
							/>
							{pageData.gallery.items.length ? <GallerySection {...pageData.gallery} /> : null}
							<CallToAction />
							<PartnersSection title={pageData.partner.title} />
						</>
					) : null}
				</Resize>
			</HomeStyle>
		</Page>
	);
});

export default withUIContext(withDataContext(HomeTemplate, ['setPartners']), ['updatePageResize', 'updatePageResizeState']);
