import styled from 'styled-components';

const PartnersSectionStyle = styled.div`
	--sectionPadding: var(--sp9x);
	--sectionBorderRadius: var(--sp4x);
	--partnerMarginRight: var(--sp15x);
	--sectionTitleMarginBottom: var(--sp6x);
	--partnerWidth: var(--sp63x);
	--sectionMarginTop: var(--sp7x);

	margin-top: var(--sectionMarginTop);

	.section-title {
		margin-bottom: var(--sectionTitleMarginBottom);
	}

	.content {
		padding: var(--sectionPadding);
		background-color: var(--white);
		border-radius: var(--sectionBorderRadius);
		overflow: hidden;

		.running-images {
			display: flex;
			position: relative;
			overflow: hidden;

			.partner-wrap {
				display: flex;

				.partner {
					width: var(--partnerWidth);
					margin-right: var(--partnerMarginRight);

					&.last {
						margin-right: var(--partnerMarginRight);
					}

					.partner-image {
						--proportion: 39.9%;

						img {
							object-fit: contain;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--sectionPadding: var(--sp7x);
		--sectionBorderRadius: var(--sp4x);
		--partnerMarginRight: var(--sp13x);
		--sectionTitleMarginBottom: var(--sp4x);
		--partnerWidth: var(--sp46x);
		--sectionMarginTop: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--sectionPadding: var(--sp6x);
		--sectionBorderRadius: var(--sp4x);
		--partnerMarginRight: var(--sp10x);
		--sectionTitleMarginBottom: var(--sp4x);
		--partnerWidth: var(--sp35x);
		--sectionMarginTop: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--sectionPadding: var(--sp6x);
		--sectionBorderRadius: var(--sp4x);
		--partnerMarginRight: var(--sp10x);
		--sectionTitleMarginBottom: var(--sp4x);
		--partnerWidth: var(--sp34x);
		--sectionMarginTop: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--sectionPadding: var(--sp5x);
		--sectionBorderRadius: var(--sp4x);
		--partnerMarginRight: var(--sp8x);
		--sectionTitleMarginBottom: var(--sp4x);
		--partnerWidth: var(--sp31x);
		--sectionMarginTop: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--sectionPadding: var(--sp5x);
		--sectionBorderRadius: var(--sp4x);
		--partnerMarginRight: var(--sp7x);
		--sectionTitleMarginBottom: var(--sp3x);
		--partnerWidth: var(--sp24x);
		--sectionMarginTop: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--sectionPadding: var(--sp5x);
		--sectionBorderRadius: var(--sp4x);
		--partnerMarginRight: var(--sp7x);
		--sectionTitleMarginBottom: var(--sp3x);
		--partnerWidth: var(--sp17x);
		--sectionMarginTop: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--sectionPadding: var(--sp3x);
		--sectionBorderRadius: var(--sp2x);
		--partnerMarginRight: var(--sp3x);
		--sectionTitleMarginBottom: var(--sp3x);
		--partnerWidth: var(--sp18x);
		--sectionMarginTop: var(--sp2x);
	}
`;

export default PartnersSectionStyle;
