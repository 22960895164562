import { useMemo, useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';

//* Style
import PartnersSectionStyle from './style';

//* Components
import CustomLink from '@/components/common/CustomLink';
import Container from '@/components/common/Container';
import Image from '@/components/common/Image';
import Text from '@/components/common/Text';
import { getPartners } from '@/libs/get-data';

const PartnersSection = ({ title }) => {
	const [items, setItems] = useState([]);

	//! Refs
	const runningPartnersRef1 = useRef();
	const runningPartnersRef2 = useRef();
	const runningPartnersRef3 = useRef();

	const runningPartners1 = useMemo(() => new gsap.timeline({ repeat: -1, paused: true }), []);
	const runningPartners2 = useMemo(() => new gsap.timeline({ repeat: -1, paused: true }), []);
	const runningPartners3 = useMemo(() => new gsap.timeline({ repeat: -1, paused: true }), []);

	useEffect(() => {
		getPartners().then((data) => setItems(data));
	}, []);

	useEffect(() => {
		if (items && runningPartnersRef1.current) {
			runningPartners1.progress(0).clear();
			runningPartners2.progress(0).clear();
			runningPartners3.progress(0).clear();

			runningPartners1.to(runningPartnersRef1.current, {
				x: '-100%',
				ease: 'none',
				duration: 5,
			});
			runningPartners2.to(runningPartnersRef2.current, {
				x: '-100%',
				ease: 'none',
				duration: 5,
			});
			runningPartners3.to(runningPartnersRef3.current, {
				x: '-100%',
				ease: 'none',
				duration: 5,
			});

			runningPartners1.play();
			runningPartners2.play();
			runningPartners3.play();
		}
	}, [items, runningPartnersRef1.current, runningPartnersRef2.current, runningPartnersRef3.current]);

	//! Partners
	const storePartners = useMemo(() => {
		if (items.length == 1) {
			setItems([...items, ...items]);
		}
		return items?.map((partner, index) =>
			partner.url ? (
				<CustomLink
					external
					key={index}
					url={partner.url}
					className={`partner ${index === partner.length - 1 ? 'last' : ''} `}>
					<Image
						className={'partner-image'}
						src={partner.logo.url}
						alt={partner.logo.alt}
					/>
				</CustomLink>
			) : (
				<div
					key={index}
					className={`partner ${index === partner.length - 1 ? 'last' : ''} `}>
					<Image
						className={'partner-image'}
						src={partner.logo.url}
						alt={partner.logo.alt}
					/>
				</div>
			)
		);
	}, [items]);

	return (
		<PartnersSectionStyle>
			{items.length !== 0 && (
				<Container>
					<div className={'content'}>
						<Text
							className={'h5 font-montserrat-medium section-title'}
							text={title}
						/>
						<div className='running-images'>
							<div
								className='partner-wrap'
								ref={runningPartnersRef1}>
								{storePartners}
							</div>
							<div
								className='partner-wrap'
								ref={runningPartnersRef2}>
								{storePartners}
							</div>
							<div
								className='partner-wrap'
								ref={runningPartnersRef3}>
								{storePartners}
							</div>
						</div>
					</div>
				</Container>
			)}
		</PartnersSectionStyle>
	);
};

export default PartnersSection;
