import { useRef, useEffect, useState, memo } from 'react';
import gsap from 'gsap';

//* Style
import HeroSectionStyle from './style';

//* HOO'C
import { withDataContext, withLanguageContext } from '@/context';

//* lib
import { getBuildingInfo } from '@/libs/get-data';

//* Components
import InfoBuildings from './InfoBuildings';

const HeroSection = ({ selectedLang, globalData }) => {
	//! State
	const [imageData, setImageData] = useState();

	//! Refs
	const imageRef = useRef();
	const wrapperImage = useRef();
	const dataPathG = useRef([]);
	const refImageWrapper = useRef();

	//! State
	const [buildingData, setBuildingData] = useState(false);

	useEffect(() => {
		getBuildingInfo({ lang: selectedLang }).then((data) => {
			const dataNameID = globalData[selectedLang].buildings.map((item) => ({
				name: item.name,
				id: item.id,
				hover: false,
			}));

			const dataImage = dataNameID.map((item) => {
				const matchedBuilding = data.data.find((elem) => elem.id === item.id);
				return matchedBuilding ? { ...matchedBuilding, hover: true } : { ...item };
			});

			setBuildingData(dataImage);
		});
	}, []);

	//! get svg Hero
	useEffect(() => {
		async function insertSVG() {
			try {
				const response = await fetch('/images/hero.svg');
				const svgText = await response.text();
				setImageData(svgText);
			} catch (error) {
				console.error(error);
			}
		}
		insertSVG();
	}, []);

	//! load Svg
	useEffect(() => {
		if (imageData && buildingData) {
			buildingData.push({ name: 'Z' });
			buildingData.unshift({ name: 'A' });

			wrapperImage.current.innerHTML = imageData;

			//! static on svg by id
			const ids = ['A', 'B', 'G', 'D', 'E', 'Z'];

			dataPathG.current = ids.map((id) => wrapperImage.current.querySelector(`#${id}`));
			dataPathG.current.map((item) => {
				if (item) item.style.opacity = 0;
			});
			if (refImageWrapper.current) {
				refImageWrapper.current.style.minHeight = 'auto';
			}
			if (imageRef.current) {
				gsap.to(imageRef.current, { clipPath: 'inset(0% 0% 0% 0%)', ease: 'power3.easeOut', duration: 1.2, delay: 0.5 }).then(() => {
					imageRef.current.classList.remove('wrapper-hero');
					imageRef.current.style.clipPath = 'none';
				});
			}
		}
	}, [imageData, buildingData]);

	useEffect(() => {
		if (wrapperImage.current) {
		}
	}, [wrapperImage.current]);

	return (
		<HeroSectionStyle>
			<div
				className='wrapper-hero'
				ref={imageRef}>
				<div
					ref={refImageWrapper}
					className={'hero'}>
					<div
						className='inner-svg-wrapper'
						ref={wrapperImage}
					/>
				</div>

				{buildingData.length >= 0 &&
					buildingData.map((item, i) => {
						return (
							<InfoBuildings
								dataG={dataPathG.current[i]}
								className={'wrapper-item'}
								key={i}
								{...item}
							/>
						);
					})}
			</div>
		</HeroSectionStyle>
	);
};

export default withDataContext(withLanguageContext(HeroSection, ['selectedLang']), ['globalData']);
