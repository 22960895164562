import styled from 'styled-components';

const GallerySectionStyle = styled.div`
	--galleryTitleMarginBottom: var(--sp12x);
	--arrowRightAndLeftPosition: var(--sp4x);

	margin-bottom: var(--sectionDistance);

	.gallery-title-wrap {
		overflow: hidden;
		margin-bottom: var(--galleryTitleMarginBottom);

		/* .gallery-title {
			transform: translateY(100%);
		} */
	}

	.slides-container {
		position: relative;
		overflow: hidden;

		.prev-button {
			position: absolute;
			top: 50%;
			left: var(--arrowRightAndLeftPosition);
			transform: translateY(-50%);
		}

		.next-button {
			position: absolute;
			top: 50%;
			right: var(--arrowRightAndLeftPosition);
			transform: translateY(-50%) rotate(180deg);
		}

		.slides-wrap {
			transform: scale(1.2);

			.slide {
				--proportion: 49.2%;

				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				transition: 0.8s ease-out;
				pointer-events: none;

				&.next-slide {
					clip-path: inset(0% 0% 0% 100%);
					overflow: hidden;
					z-index: 2;
					transition: 0.8s ease-out;
				}

				&.prev-slide {
					clip-path: inset(0% 100% 0% 0%);
					overflow: hidden;
					z-index: 2;
					transition: 0.8s ease-out;
				}

				&.active {
					position: relative;
					pointer-events: all;
					clip-path: inset(0% 0% 0% 0%);
					transition: 0.8s ease-out;
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--galleryTitleMarginBottom: var(--sp8x);
		--arrowRightAndLeftPosition: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--galleryTitleMarginBottom: var(--sp8x);
		--arrowRightAndLeftPosition: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--galleryTitleMarginBottom: var(--sp8x);
		--arrowRightAndLeftPosition: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--galleryTitleMarginBottom: var(--sp6x);
		--arrowRightAndLeftPosition: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--galleryTitleMarginBottom: var(--sp5x);
		--arrowRightAndLeftPosition: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--galleryTitleMarginBottom: var(--sp5x);
		--arrowRightAndLeftPosition: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--galleryTitleMarginBottom: var(--sp3x);
		--arrowRightAndLeftPosition: var(--sp1-5x);

		.slides-container {
			.slides-wrap {
				.slide {
					--proportion: 73.8%;
				}
			}
		}
	}
`;

export default GallerySectionStyle;
