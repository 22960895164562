import styled from 'styled-components';

const CallToActionStyle = styled.div`
	--callToActionUpperPadding: var(--sp30x) var(--sp25x);
	--callToActionBorderRadius: var(--sp4x);
	--circleSizes: var(--sp41x);

	--staticCursorPositionTop: 0;
	--staticCursorPositionRight: 0;
	--callToActionHeight: var(--sp76x);

	--upperTextWrapWidth: var(--sp183x);

	.call-to-action {
		position: relative;
		display: flex;
		justify-content: center;
		padding: var(--callToActionUpperPadding);
		border-radius: var(--callToActionBorderRadius);
		cursor: none;
		/* height: var(--callToActionHeight); */

		.static-cursor {
			display: none;
			position: absolute;
			top: var(--staticCursorPositionTop);
			right: var(--staticCursorPositionRight);

			.call-to-action-drag {
				display: flex;
				align-items: center;
				justify-content: center;
				width: var(--circleSizes);
				height: var(--circleSizes);
				border-radius: 50%;
				color: var(--white);
				background-color: var(--red1000);
				transform: rotate(-15deg);
				text-align: center;
			}
		}

		//! Cursor styles
		.call-to-action-drag-container {
			position: absolute;
			z-index: 1000;
			pointer-events: none;

			.call-to-action-drag {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: var(--circleSizes);
				height: var(--circleSizes);
				border-radius: 50%;
				color: var(--white);
				background-color: var(--red1000);
				transform: translate(-50%, -50%) rotate(-15deg);
				text-align: center;
			}
		}

		.upper-text-wrap {
			/* width: var(--upperTextWrapWidth); */
			text-align: center;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--callToActionUpperPadding: var(--sp25x) var(--sp18x);
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp31x);
		--callToActionHeight: var(--sp63x);
		--upperTextWrapWidth: var(--sp155x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--callToActionUpperPadding: var(--sp20x) var(--sp15x);
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp27x);
		--callToActionHeight: var(--sp50x);
		--upperTextWrapWidth: var(--sp113x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--callToActionUpperPadding: var(--sp20x) var(--sp15x);
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp27x);
		--callToActionHeight: var(--sp50x);
		--upperTextWrapWidth: var(--sp113x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--callToActionUpperPadding: var(--sp15x) var(--sp12x);
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp24x);
		--callToActionHeight: var(--sp37x);
		--upperTextWrapWidth: var(--sp85x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--callToActionUpperPadding: var(--sp12x) var(--sp23x);
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp20x);

		--staticCursorPositionTop: var(--sp3x);
		--staticCursorPositionRight: var(--sp3x);
		--callToActionHeight: var(--sp36x);
		--upperTextWrapWidth: unset;

		.call-to-action {
			.static-cursor {
				display: block;
			}

			.upper-text {
				/* height: var(--sp12x); */
				/* max-width: var(--sp45x); */
				margin: 0 auto;
				text-align: center;
			}
		}

		.call-to-action-drag-container {
			display: none;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--callToActionUpperPadding: var(--sp12x) var(--sp23x);
		--callToActionBorderRadius: var(--sp4x);
		--circleSizes: var(--sp20x);

		--staticCursorPositionTop: var(--sp3x);
		--staticCursorPositionRight: var(--sp3x);
		--callToActionHeight: var(--sp36x);
		--upperTextWrapWidth: unset;

		.call-to-action {
			.static-cursor {
				display: block;
			}
			.upper-text {
				/* height: var(--sp12x); */
				/* max-width: var(--sp45x); */
				margin: 0 auto;
				text-align: center;
			}
		}

		.call-to-action-drag-container {
			display: none;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--callToActionUpperPadding: var(--sp9x) var(--sp3x);
		--callToActionBorderRadius: var(--sp2x);
		--circleSizes: var(--sp20x);

		--staticCursorPositionTop: var(--sp3x);
		--staticCursorPositionRight: var(--sp3x);
		--callToActionHeight: var(--sp51x);
		--upperTextWrapWidth: unset;

		.call-to-action {
			display: flex;
			flex-direction: column;

			.static-cursor {
				display: block;
				position: static;
				margin: var(--sp3x) auto 0;
				text-align: center;
			}

			.upper-text {
				/* height: var(--sp9x); */
				/* max-width: var(--sp45x); */
				text-align: center;
				margin: 0 auto;
			}
		}

		.footer-drag-container {
			display: none;
		}

		.call-to-action-drag-container {
			display: none;
		}
	}
`;

export default CallToActionStyle;
