import { useRef, useEffect, useState } from 'react';
import { ScrollTrigger } from 'gsap/all';
import { gsap } from 'gsap';

//* Style
import AboutProjectItemStyle from './style';

//* Components
import Text from '@/components/common/Text';
import Image from '@/components/common/Image';
import ScrollActive from '@/components/common/ScrollActive';

const AboutProjectItem = ({ image, title, description, className }) => {
	//! Refs
	const itemRef = useRef();
	const titleRef = useRef();
	const textRef = useRef();
	const imageRef = useRef();

	//! State
	const [percentD, setPercentD] = useState();

	useEffect(() => {
		ScrollTrigger.create({
			trigger: imageRef.current.children[0],
			start: `center 90%`,
			end: `center top`,
			// markers: true,
			once: true,
			onEnter: () => {
				if (imageRef.current?.children[0]) {
					gsap.to(imageRef.current.children[0], { scale: 1, clipPath: 'inset(0% 0% 0% 0%)', ease: 'power3.easeOut', duration: 1 });
				}
			},
		});
	}, []);

	return (
		<AboutProjectItemStyle
			ref={itemRef}
			className={className || ''}>
			<div className={`about-item`}>
				<Image
					ref={imageRef}
					src={image.url}
					alt={image.alt}
				/>
				<div className='item-title-wrap'>
					<ScrollActive
						animTitle
						typeWordLines={`lines`}
						allRefs={titleRef}
						percent
						percentDuration={40}
						percentFunction={setPercentD}>
						<Text
							className={'h2 font-montserrat item-title opacity-0'}
							ref={titleRef}>
							{title}
						</Text>
					</ScrollActive>
				</div>
				<div className='item-text-wrap'>
					<ScrollActive
						animTitle
						markerContainer={titleRef}
						allRefs={textRef}
						typeWordLines={`lines`}
						stagger={0.15}
						duration={1}>
						<Text
							className={'h5 font-montserrat item-text opacity-0'}
							ref={textRef}>
							{description}
						</Text>
					</ScrollActive>
				</div>
			</div>
		</AboutProjectItemStyle>
	);
};

export default AboutProjectItem;
