import styled from 'styled-components';

const InfoBuildingsStyle = styled.div`
	--sizeCircle: var(--sp8x);
	--contentPadLR: var(--sp3x);
	--contentPadTB: var(--sp3x);
	--radiusContent: var(--sp2-5x);
	pointer-events: none;

	width: fit-content;
	height: fit-content;
	will-change: transform;

	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	transform: translate(-50%, -50%);

	.block-wrapper {
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 1;
	}

	.circle {
		position: relative;
		z-index: 1;
		border-radius: 999px;
		background-color: var(--white);
		display: flex;
		justify-content: center;
		align-items: center;
		width: var(--sizeCircle);
		height: var(--sizeCircle);
		transition: all var(--trTime) ease-out;

		.name {
			line-height: 1;
		}
	}

	.wrapper-info {
		position: absolute;
		z-index: 10;
		background-color: var(--white);
		width: fit-content;
		height: fit-content;
		padding: var(--contentPadTB) var(--contentPadLR);
		border-radius: var(--radiusContent);
		left: calc(var(--sizeCircle));
		bottom: 2%;
		transform: scale(0);
		will-change: transform;

		.inner-wrapper-info {
			display: flex;
			flex-direction: column;
			gap: var(--sp1x);
		}
	}

	.info-price {
		white-space: nowrap;
	}

	.hovered {
		background-color: var(--black1000);
		color: var(--white);
		cursor: pointer;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--sizeCircle: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--sizeCircle: var(--sp6x);
		--contentPadLR: var(--sp2-5x);
		--contentPadTB: var(--sp2-5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--sizeCircle: var(--sp6x);
		--contentPadLR: var(--sp1-5x);
		--contentPadTB: var(--sp1-5x);
	}
	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--sizeCircle: var(--sp5x);
		--contentPadLR: var(--sp1-5x);
		--contentPadTB: var(--sp1-5x);

		.wrapper-info {
			top: calc(var(--sizeCircle));
		}
	}
	/* //!1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--sizeCircle: var(--sp5x);
		--contentPadLR: var(--sp1-5x);
		--contentPadTB: var(--sp1-5x);

		.wrapper-info {
			top: calc(var(--sizeCircle));
		}
	}
	/* //!768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--sizeCircle: var(--sp4x);
		--contentPadLR: var(--sp1-5x);
		--contentPadTB: var(--sp1-5x);

		.wrapper-info {
			top: calc(var(--sizeCircle));
		}
	}
	/* //!414 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--sizeCircle: var(--sp4x);
		--contentPadLR: var(--sp1-5x);
		--contentPadTB: var(--sp1-5x);

		.wrapper-info {
			top: calc(var(--sizeCircle));
		}
	}
`;
export default InfoBuildingsStyle;
